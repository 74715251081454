.lang-dropdown-icon {
  margin-bottom: 8px;
}

@media (max-width: 540px) {
  /* mobile */

  .sm-dropdown-container {
    font-size: 11px;
  }
}
