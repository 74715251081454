body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.trainingTabBar .ant-tabs-content-holder {
  padding-top: 20px;
  background: #e9ecf6;
  height: calc(100vh - 161px);
}
.trainingTabBar {
  margin-top: 20px;
}

.trainingWrapper {
  display: grid;
  grid-template-columns: 2fr 5fr 1fr;
  gap: 8px;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 5px;
}
@media screen and (max-width: 1280px) {
  .trainingWrapper .chartContainer {
    max-width: 768px;
  }
}
.trainingWrapper .inputContainer,
.trainingWrapper .chartContainer,
.trainingWrapper .listContainer {
  height: 500px;
  background: #fff;
  border-radius: 10px;
}
.trainingWrapper .listContainer {
  min-width: 185px;
  overflow: auto;
}
.trainingWrapper .inputHeader,
.trainingWrapper .chartHeader {
  font-weight: 800;
  font-size: 16px;
  color: #036eb8;
  margin-top: 8px;
  margin-left: 24px;
  margin-bottom: 9px;
}
.trainingWrapper .inputStatusButton {
  background: #ffc833;
  border-radius: 15px;
  display: flex;
  width: 224px;
  height: 24px;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.trainingWrapper .inputStatusButton span {
  font-weight: 900;
  font-size: 14px;
  color: #036eb8;
  margin-left: 8px;
}
.trainingWrapper .inputBody {
  margin-top: 68px;
}
.trainingWrapper .inputBody :global(.ant-input-number) {
  border: 1px solid #036eb8;
  border-radius: 5px;
}
.trainingWrapper .inputBody .container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  margin: 0 20px;
  max-width: calc(100% - 40px);
}
.trainingWrapper .inputBody .itemLabel {
  font-weight: 400;
  font-size: 10px;
  color: #036eb8;
}
.trainingWrapper .inputBody .textSuffix {
  padding-left: 4px;
  padding-top: 13px;
}
.trainingWrapper .inputBody .textSuffix span {
  font-weight: 400;
  font-size: 10px;
  color: #036eb8;
}
.trainingWrapper .inputFooter {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}
.trainingWrapper .inputFooter button {
  font-weight: 900;
  font-size: 14px;
  line-height: 21px;
  width: 240px;
  height: 40px;
  border-radius: 5px;
}
.trainingWrapper .inputFooter button:not(:disabled) {
  color: #fff;
  background: #036eb8;
  cursor: pointer;
}
.trainingWrapper .inputFooter :disabled {
  color: rgba(0, 0, 0, 0.25);
  border: 1px solid #d9d9d9;
  background: #f5f5f5;
  cursor: not-allowed;
}
.trainingWrapper .inputFooter button:hover:not(:disabled) {
  border: 1px solid #40a9ff;
  background: #2387c4;
}
.trainingWrapper :global(.ant-form-item-explain-error) {
  width: 90px;
}
.trainingWrapper .listContainer {
  padding-top: 30px;
}
.trainingWrapper .listHeader {
  display: flex;
  justify-content: space-around;
  margin: 0 20px;
  border-bottom: 1px solid #c4c4c4;
}
.trainingWrapper .listHeader span {
  font-weight: 900;
  font-size: 10px;
  color: #c4c4c4;
}
.trainingWrapper .listContent {
  display: flex;
  justify-content: space-around;
  margin: 4px 20px 0 20px;
}
.trainingWrapper .listContent span {
  font-weight: 400;
  font-size: 10px;
  color: #025fa1;
  padding-left: 4px;
}
.trainingWrapper .chartDescription {
  display: flex;
  justify-content: space-between;
  margin: 24px 56px 24px 42px;
}
.trainingWrapper .chartLeftContent {
  display: flex;
  background: #e9ecf6;
  border-radius: 5px;
  padding: 1px;
  height: 22px;
}
.trainingWrapper .switchButton {
  display: flex;
  justify-content: center;
  width: 70px;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}
.trainingWrapper .switchButton span {
  font-weight: 900;
  font-size: 10px;
  color: #000;
  padding-top: 5px;
}
.trainingWrapper .headerContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 44px;
}
.trainingWrapper .headerContent span {
  font-weight: 900;
  font-size: 10px;
  color: #c4c4c4;
}
.trainingWrapper .bodyContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 44px;
  margin-left: 4px;
}
.trainingWrapper .bodyContent span {
  font-weight: 900;
  font-size: 16px;
  color: #036eb8;
}
.trainingWrapper .lineChart {
  height: 300px;
  padding: 0 40px 0 10px;
  position: relative;
}
.leftArrow {
  position: absolute;
  left: 24px;
  bottom: 13px;
  cursor: pointer;
}
.rightArrow {
  position: absolute;
  right: 37px;
  bottom: 13px;
  cursor: pointer;
}

.imageChangeDate {
  width: 12px;
  max-height: 12px;
  position: relative;
  top: -6px;
}

@media screen and (max-width: 1024px) {
  .trainingWrapper .inputBody .container {
    grid-template-columns: 1fr;
  }
  .trainingWrapper .inputFooter button {
    width: 100px;
  }
  .trainingWrapper .chartContainer {
    max-width: 550px;
  }
}

@media screen and (max-width: 868px) {
  .trainingWrapper .inputBody .container {
    display: flex;
    justify-content: space-around;
  }
  .trainingWrapper {
    display: block;
  }
  .trainingWrapper .inputContainer {
    height: max-content;
  }
  .trainingWrapper .inputBody,
  .trainingWrapper .inputFooter {
    margin-top: 12px;
  }
  .trainingWrapper .inputHeader,
  .trainingWrapper .chartHeader {
    padding-top: 8px;
  }
  .trainingWrapper .chartContainer {
    max-width: calc(100vw - 128px);
    margin-bottom: 8px;
  }
  .trainingWrapper .listContainer {
    height: max-content;
    padding: 8px 0;
  }
}

@media screen and (max-width: 540px) {
  .trainingWrapper {
    padding: 0;
  }

  .trainingWrapper .inputBody .container {
    display: block;
  }

  .trainingWrapper .inputBody .container .flex {
    display: inline-flex;
    width: 50%;
    text-align: center;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .textSuffix {
    position: absolute;
    right: -24px;
  }

  .trainingWrapper .chartContainer {
    max-width: calc(100vw - 24px);
    height: max-content;
  }

  .trainingWrapper .chartHeader {
    margin-left: 12px;
  }

  .trainingWrapper .chartDescription {
    margin: 12px;
  }

  .trainingWrapper .lineChart {
    padding: 0;
  }

  .leftArrow {
    left: 8px;
    bottom: 19px;
  }

  .rightArrow {
    right: 8px;
    bottom: 19px;
  }

  .trainingWrapperForm .flex.flex-col {
    align-items: center;
  }
  .trainingWrapperForm .flex.flex-col .flex {
    position: relative;
    display: inline-flex;
  }
}
