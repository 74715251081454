@media screen and (max-height: 680px) {
  .qr-code-form .title {
    padding-bottom: 4px !important;
  }

  .qr-code-form .sso-group {
    margin-top: 4px !important;
  }

  .qr-code-form .qr-reader {
    width: 70% !important;
  }
}

/* Default QR reader size */
.qr-code-form .qr-reader {
  width: 87%;
}
