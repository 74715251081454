/* https://css-tricks.com/snippets/css/ipad-specific-css/ */
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
  /* For landscape layouts only */
  .ant-input-number {
    width: 80px !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  /* For landscape layouts only */
  .ant-input-number {
    width: 68px !important;
  }
}
