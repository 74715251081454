.trainingWrapper {
    display: grid;
    grid-template-columns: 2fr 5fr 1fr;
    gap: 8px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 5px;

    & .chartContainer {
        @media screen and (max-width: 1280px) {
            max-width: 768px;
        }
        @media screen and (max-width: 1024px) {
            max-width: 550px;
        }
    }

    & .inputContainer,
    .chartContainer,
    .listContainer {
        height: 500px;
        background: #fff;
        border-radius: 10px;
    }

    & .listContainer {
        min-width: 185px;
        overflow: auto;
    }

    & .inputHeader,
    .chartHeader {
        font-weight: 800;
        font-size: 16px;
        color: #036EB8;
        margin-top: 8px;
        margin-left: 24px;
        margin-bottom: 9px;
    }

    & .inputStatusButton {
        background: #FFC833;
        border-radius: 15px;
        display: flex;
        width: 224px;
        height: 24px;
        justify-content: center;
        align-items: center;
        margin: auto;

        & span {
            font-weight: 900;
            font-size: 14px;
            color: #036EB8;
            margin-left: 8px;
        }
    }

    & .inputBody {
        margin-top: 68px;

        :global(.ant-input-number) {
            border: 1px solid #036EB8;
            border-radius: 5px;
        }

        & .container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            justify-items: center;
            margin: 0 20px;
            @media screen and (max-width: 1024px) {
              grid-template-columns: 1fr;
            }
        }

        & .itemLabel {
            font-weight: 400;
            font-size: 10px;
            color: #036EB8;
        }

        & .textSuffix {
            padding-left: 4px;
            padding-top: 13px;

            & span {
                font-weight: 400;
                font-size: 10px;
                color: #036EB8;
            }
        }
    }

    & .inputFooter {
        display: flex;
        justify-content: center;
        margin-top: 60px;

        & button {
            font-weight: 900;
            font-size: 14px;
            line-height: 21px;
            width: 240px;
            height: 40px;
            border-radius: 5px;
            @media screen and (max-width: 1024px) {
              width: 100px;
            }
        }

        & button:not(:disabled) {
            color: #fff;
            background: #036EB8;
            cursor: pointer;
        }

        & :disabled {
            color: rgba(0, 0, 0, .25);
            border: 1px solid #d9d9d9;
            background: #f5f5f5;
            cursor: not-allowed;
        }

        & button:hover:not(:disabled) {
            border: 1px solid #40a9ff;
            background: #2387c4;
        }
    }

    :global(.ant-form-item-explain-error) {
        width: 90px;
    }

    & .listContainer {
        padding-top: 30px;
    }

    & .listHeader {
        display: flex;
        justify-content: space-around;
        margin: 0 20px;
        border-bottom: 1px solid #C4C4C4;

        & span {
            font-weight: 900;
            font-size: 10px;
            color: #C4C4C4;
        }
    }

    & .listContent {
        display: flex;
        justify-content: space-around;
        margin: 4px 20px 0 20px;

        & span {
            font-weight: 400;
            font-size: 10px;
            color: #025FA1;
            padding-left: 4px;
        }
    }

    & .chartDescription {
        display: flex;
        justify-content: space-between;
        margin: 24px 56px 24px 42px;
    }

    & .chartLeftContent {
        display: flex;
        background: #E9ECF6;
        border-radius: 5px;
        padding: 1px;
        height: 22px;
    }

    & .switchButton {
        display: flex;
        justify-content: center;
        width: 70px;
        align-items: center;
        border-radius: 5px;
        cursor: pointer;

        & span {
            font-weight: 900;
            font-size: 10px;
            color: #000000;
        }
    }

    & .headerContent {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 44px;

        & span {
            font-weight: 900;
            font-size: 10px;
            color: #C4C4C4;
        }
    }

    & .bodyContent {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 44px;
        margin-left: 4px;

        & span {
            font-weight: 900;
            font-size: 16px;
            color: #036EB8;
        }
    }

    & .lineChart {
        height: 300px;
        padding: 0 40px 0 10px;
        position: relative;

        & .leftArrow {
            position: absolute;
            left: 24px;
            bottom: 13px;
            cursor: pointer;
        }

        & .rightArrow {
            position: absolute;
            right: 37px;
            bottom: 13px;
            cursor: pointer;
        }
    }

  @media screen and (max-width: 868px) {
    & .inputBody .container {
      display: flex;
      justify-content: space-around;
    }
    & {
      display: block;
    }
    & .inputContainer {
      height: max-content;
    }
    & .inputBody, & .inputFooter {
      margin-top: 12px;
    }
    & .inputHeader, & .chartHeader {
      padding-top: 8px;
    }
    & .chartContainer {
      max-width: calc(100vw - 128px);
      margin-bottom: 8px;
    }
    & .listContainer {
      height: max-content;
      padding: 8px 0;
    }
  }

  .leftArrow {
    position: absolute;
    left: 24px;
    bottom: 13px;
    cursor: pointer;
  }
  .rightArrow {
    position: absolute;
    right: 37px;
    bottom: 13px;
    cursor: pointer;
  }

  @media screen and (max-width: 540px) {
    padding: 0;

    & .inputBody .container .flex {
      display: inline-flex;
      width: 50%;
      text-align: center;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    & .inputBody .textSuffix {
      position: absolute;
      right: -30px;
    }

    & .chartContainer {
      max-width: calc(100vw - 24px);
      height: max-content;
    }

    & .chartHeader {
      margin-left: 12px;
    }

    & .chartDescription {
      margin: 12px;
    }

    & .lineChart {
      padding: 0;
    }

    & .lineChart .leftArrow {
      left: 8px;
      bottom: 19px;
    }

    & .lineChart .rightArrow {
      right: 8px;
      bottom: 19px;
    }
  }

}
