.right-menu {
  z-index: 51;
}

@media (max-width: 540px) {
  /* mobile */

  .right-menu {
    height: 64px !important;
  }
}

@media (min-width: 541px) {
  /* iPad or PC */

  .menu-dashboard-pc {
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    height: calc(100% - 80px);
  }

  .menu-dashboard-pc .menu-item {
    flex: auto;
  }

  .main-menu-pc {
    width: 80px;
  }

  .children-content {
    /* children content is not showing fully on iPad, so adding more padding */
    /* fix for scroll on iPad */
    padding-bottom: 3rem;
  }
}
