.table-test thead th {
  padding-bottom: 0;
  height: 53px;
}

.table-test tbody tr {
  height: 53px;
  border-bottom: 1px solid #acabab;
}

.table-test tbody tr td {
  align-items: center;
  width: 7.73vw;
  min-width: 112px;
}

.table-test .test-title span {
  color: #333333;
}

.table-test tbody td {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.rating-title {
  padding: 4px 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  background: #036eb8;
  border-radius: 16px;
}

.rating-title-hina {
  padding: 16px 16px 8px 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  background: #036eb8;
  border-radius: 16px;
}

.rating-title-en {
  padding: 8px 12px;
  font-size: 12px;
}

.row-header {
  min-width: 175px;
  width: max-content;
  color: #333333;
}

.test-icon {
  min-width: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1366px) {
  .rating-title-en {
    font-size: 10px !important;
  }
}

@media (max-width: 768px) {
  .rating-title-en {
    font-size: 9px !important;
  }
}

@media (max-width: 540px) {
  .table-test tbody td {
    font-size: 11px;
    line-height: 12px;
  }

  .test-res-num {
    font-size: 16px !important;
  }

  .rating-title {
    padding: 4px 16px;
    line-height: 20px;
  }

  .rating-title-hina {
    padding: 8px 16px;
    height: fit-content;
    margin: auto;
    display: flex;
    width: fit-content;
  }
}
