.tabBar {
    margin-top: 20px;

    :global(.ant-tabs-tab) {
        width: 160px;
        height: 61px;
        display: flex;
        justify-content: center;
        border-radius: 10px 10px 0px 0px;
        margin: 0;
    }

    :global(.ant-tabs-content-holder) {
        padding-top: 20px;
        background: #E9ECF6;
        height: calc(100vh - 161px);
    }

    :global(.ant-tabs-nav) {
        margin: 0;
    }

    :global(.ant-tabs-tab:nth-child(even)) {
        background: #E5E5E5;
    }

    :global(.ant-tabs-tab:nth-child(odd)) {
        background: #C4C4C4;
    }

    :global(.ant-tabs-tab-btn:not(:nth-child(5))) {
        color: #fff;
        font-size: 20px;
        font-weight: 900;
    }

    :global(.ant-tabs-tab-active) {
        background: #E9ECF6 !important;
    }

    :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
        color: #025FA1;
    }


    :global(.ant-tabs-nav-operations) {
        display: none !important;
    }

    :global(.ant-tabs-ink-bar) {
        background-color: transparent;
    }

}